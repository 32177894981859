import React, { useState } from 'react';

const ApiForm = ({ api, onSubmit }) => {
    const [formValues, setFormValues] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formValues);
    };

    return (
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-4 mb-4">
            <h3 className="text-lg font-bold mb-4">{`Parameters for ${api.name}`}</h3>
            <div className="space-y-4">
                {api.params.map((param) => (
                    <div key={param.name}>
                        <label htmlFor={param.name} className="block text-sm font-medium text-gray-700">
                            {param.placeholder}
                        </label>
                        <input
                            type={param.type}
                            name={param.name}
                            id={param.name}
                            value={formValues[param.name] || ''}
                            onChange={handleChange}
                            placeholder={param.placeholder}
                            className="input input-bordered w-full"
                        />
                    </div>
                ))}

                <button type="submit" className="btn btn-primary w-full">
                    Submit
                </button>
            </div>
        </form>
    );
};

export default ApiForm;
