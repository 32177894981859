import React from 'react';

const MessageDisplay = ({ messages, loading }) => {
  return (
    <div className="bg-gray-50 rounded-lg shadow-md p-4 h-64 overflow-y-auto">
      <h2 className="text-lg font-semibold mb-4">Response</h2>

      {loading && (
        <div className="flex justify-center mb-4">
          <div className="loading-spinner"></div>
        </div>
      )}

      {messages.map((message, index) => (
        <pre
          key={index}
          className="bg-gray-100 p-4 rounded-lg overflow-x-auto text-sm text-gray-800"
        >
          {message}
        </pre>
      ))}

      {!loading && messages.length === 0 && (
        <p className="text-gray-500 text-sm text-center">No messages to display.</p>
      )}
    </div>
  );
};

export default MessageDisplay;
