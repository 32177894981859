import ApiInterface from './components/ApiInterface';
import AccessCodeScreen from './components/AccessCodeScreen';
import React, { useState } from 'react';

function App() {
  const [accessGranted, setAccessGranted] = useState(false);

  return (
    <div>
      {accessGranted ? (
        <ApiInterface />
      ) : (
        <AccessCodeScreen onAccessGranted={() => setAccessGranted(true)} />
      )}
    </div>
  );    
}

export default App;
