import React, { useState, useEffect } from 'react';
import ApiForm from './ApiForm';
import MessageDisplay from './ApiResponse';

const ApiInterface = () => {
    const apis = [
        {
          id: 1,
          name: 'Match Making Prompt Testing',
          endpoint: 'https://api.smartmaheshwari.com/matchmaking_prompt_testing',
          method: 'POST',
          params: [
            { name: 'userId', type: 'text', placeholder: 'Enter User ID' },
            { name: 'candidateId', type: 'text', placeholder: 'Enter Candidate ID' },
            { name: 'promptId', type: 'number', placeholder: 'Enter Prompt ID' },
          ],
        },
        {
          id: 2,
          name: 'Tag Extraction Prompt Testing',
          endpoint: 'https://api.smartmaheshwari.com/tag_extraction_promot_testing',
          method: 'POST',
          params: [
            { name: 'UserId', type: 'text', placeholder: 'Enter User ID' },
            { name: 'MatrimonialId', type: 'text', placeholder: 'Enter Matrimonial ID' },
            { name: 'PromptId', type: 'number', placeholder: 'Enter Prompt ID' },
          ],
        },
      ];
    
      

  // Set the initial API selection to the first API in the list
  const [selectedApi, setSelectedApi] = useState(apis[0]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleApiClick = (api) => {
    setSelectedApi(api);
    setMessages([]);
  };

  const handleSubmit = async (params) => {
    setLoading(true);
    setMessages([]);
  
    try {
      const { method, endpoint } = selectedApi;
  
      let response;
  
      if (method === 'GET') {
        const query = new URLSearchParams(params).toString();
        response = await fetch(`${endpoint}?${query}`, { method });
      } else if (method === 'POST') {
        response = await fetch(endpoint, {
          method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        });
      }
  
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Display the entire JSON response as a formatted string
      const formattedJson = JSON.stringify(data, null, 2); 
      setMessages([formattedJson]);
    } catch (error) {
      setMessages([`Error fetching data: ${error.message}`]);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="p-6">
      {/* Heading */}
      <h1 className="text-2xl font-bold text-center mb-8">Test SMM APIs</h1>

      <div className="flex flex-col md:flex-row gap-6">
        {/* API List */}
        <div className="w-full md:w-1/3 bg-gray-100 rounded-lg shadow-md p-4">
          <h3 className="text-lg font-bold mb-4">Available APIs</h3>
          <ul>
            {apis.map((api) => (
              <li key={api.id}>
                <button
                  onClick={() => handleApiClick(api)}
                  className={`w-full text-left px-4 py-2 rounded ${
                    selectedApi?.id === api.id
                      ? 'bg-blue-100 font-semibold'
                      : 'hover:bg-gray-200'
                  } focus:outline-none focus:ring`}
                >
                  {api.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* API Form and Messages */}
        <div className="w-full md:w-2/3">
          {selectedApi && (
            <>
              <ApiForm api={selectedApi} onSubmit={handleSubmit} />
              <MessageDisplay messages={messages} loading={loading} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiInterface;
